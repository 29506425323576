import React from "react"
import { graphql } from "gatsby"

import { useShopify } from "../hooks/useShopify"
import { useSale } from "../hooks/useSale"

import { Sections as Page } from "../components/Sections/Sections"

export const query = graphql`
  query {
    page: sanityPageHomepage {
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
      content: _rawContent(resolveReferences: { maxDepth: 9 })
    }
    promotions: allSanityPromotion {
      edges {
        node {
          title
          slug {
            current
          }
          promotion {
            desktopHeader: _rawDesktopHeader(resolveReferences: { maxDepth: 10 })
            mobileHeader: _rawMobileHeader(resolveReferences: { maxDepth: 10 })
            homepage: _rawHomepage(resolveReferences: { maxDepth: 10 })
          }
        }
      }
    }
  }
`

const Component = ({ data, ...props }) => {
  const { getOnSale } = useSale()
  const { edgeNormaliser } = useShopify()
  const promotions = data?.promotions
    ? edgeNormaliser(data?.promotions).reduce(
        (result, item) =>
          item?.slug?.current
            ? { ...result, [item.slug.current]: { metadata: item?.promotion?.homepage?.metadata, content: item?.promotion?.homepage?.content } }
            : result,
        {}
      )
    : {}
  const saleName = getOnSale()

  return <Page {...props} page={saleName && promotions[saleName] ? promotions[saleName] : data.page} />
}
export default Component
